'use client'
import React, { FC, useMemo } from 'react'
import { HStack, VStack } from '@chakra-ui/react'

import { getProductImages } from 'api'
import { GrandHeading, GrandNextLink, GrandText, HoverableImages } from 'ui'
import {
  ProductBadges,
  ProductListItemBuy,
  ProductListItemProps
} from 'storefront-modules/products'

const CustomProductListItem: FC<ProductListItemProps> = ({
  onBuy,
  onProductRequest,
  item,
  deliveryOptions,
  productsUrlPath,
  showsPrice,
  ...props
}) => {
  const { mainImageUrl, secondaryImageUrl } = getProductImages(item.medias)
  const productBadges = useMemo(
    () => item?.tags?.filter?.((tag) => tag?.type === 'BADGE') || [],
    [item?.tags]
  )

  return (
    <VStack
      _hover={{
        transform: {
          base: 'none',
          lg: 'translateY(-5px)'
        }
      }}
      bg="background.default"
      borderRadius="sm"
      className="custom-product-list-item"
      justifyContent="center"
      position="relative"
      py={4}
      shadow="md"
      spacing={1}
      transition="all 0.1s cubic-bezier(.17,.67,.83,.67)"
      {...props}>
      <HStack justifyContent="space-between" px={4} w="full">
        <GrandText
          color="gray.500"
          flex={1}
          fontSize="sm"
          overflow="hidden"
          overflowWrap="normal"
          textOverflow="ellipsis">
          {item.articleNumber}
        </GrandText>
        <GrandText color="gray.500" flex={1} fontSize="sm" textAlign="right">
          {item.brand?.name}
        </GrandText>
      </HStack>
      <GrandNextLink aria-label={item.name} h="full" href={`/p/${item.slug}`} w="full">
        <HoverableImages
          alt={item.name}
          badges={
            <ProductBadges
              badges={productBadges}
              imageProps={{
                sizes: `(min-width: 40em) 90px, 
                  (min-width: 30em) 80px,
                  60px
                `
              }}
              scalableSize={[60, 80, 90]}
            />
          }
          firstImgUrl={mainImageUrl}
          secondImgUrl={secondaryImageUrl || ''}
          title={item.name}
        />
      </GrandNextLink>

      <GrandHeading
        as="h3"
        fontSize={{
          base: 'sm',
          md: 'md'
        }}
        isTruncated
        pt={4}
        textAlign="center"
        title={item.name}
        tooltip={item.name}
        w="80%"
      />

      {showsPrice && (
        <ProductListItemBuy
          price={{ price: item.price, originalPrice: item.originalPrice }}
          product={item}
          {...{ deliveryOptions, onBuy, onProductRequest, productsUrlPath }}
        />
      )}
    </VStack>
  )
}

export default CustomProductListItem
